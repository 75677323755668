body {
  color: #555;
}

.menu-item {
  cursor: pointer;
}

.sec-title {
  font-weight: 300;
  padding: 10px 10px;
  background: #eee;
  border-left: 4px solid #007bff;
}

.card-selectable:hover {
  cursor: pointer;
  background: #eee;
  transform: scale(1.02);
}

.card-selectable.selected {
  color: #fff;
  background: #007bff;
}

.issue-like:hover {
  cursor: pointer;
}
.modal-xxl {
  width: 95%;
  max-width: 95%;
}

.side-menu {
  position: fixed;
  top: 0;
  right: 0;
  min-width: 400px;
  min-height: 100vh;
  padding: 0 20px;
  /* background: rgba(79, 37, 130, .9); */
  background: rgba(0, 123, 255, 1);
  z-index: 100;
}

.side-menu-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 90;
}

.menu-close {
  color: #fff;
  text-align: center;
  margin: 10px 10px 0 10px;
  padding: 15px 15px 5px 15px;
  font-size: 30px;
  cursor: pointer;
}

.menu-item {
  color: #444;
  text-align: left;
  margin: 20px;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
}
.menu-item > a,
.menu-item > span {
  display: block;
  padding: 20px;
  color: inherit;
  text-decoration: none;
}

.menu-item .icon {
  float: left;
  width: 50px;
  height: 45px;
  margin-right: 15px;
}

/* Chat bubbles */
.message small b {
  opacity: 0.7;
  font-weight: 300;
}
.message .bg-kellogg,
.offer-box.bg-kellogg {
  border: none;
  background: rgba(231, 232, 252, 0.5);
  /* box-shadow: -2px 3px 5px 1px rgba(79, 37, 130, 0.2); */
  box-shadow: 0px 3px 3px 0px rgb(0, 0, 0, 0.1);
  border: 1px solid #e6e6e6;
}
.message .bg-white,
.offer-box.bg-white {
  border: none;
  background: #f9f9f9 !important;
  box-shadow: 0px 3px 3px 0px rgb(0, 0, 0, 0.1);
  border: 1px solid #e6e6e6;
}

/* ARROW */
.message .card:after,
.message .card:before {
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.message .card.float-left {
  border-top-left-radius: 0;
}
.message .card.float-right {
  border-top-right-radius: 0;
}

.message .card.float-left:after {
  right: 100%;
  top: 10px;
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #f9f9f9;
  border-right-color: #f9f9f9;
  border-width: 10px;
  margin-top: -10px;
  margin-right: -3px;
}
.message .card.float-left:before {
  right: 100%;
  top: 10px;
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #e6e6e6;
  border-right-color: #e6e6e6;
  border-width: 10px;
  margin-top: -11px;
}

.message .card.float-right:after {
  left: 100%;
  top: 10px;
  border-color: rgba(136, 183, 213, 0);
  border-top-color: rgb(243, 244, 254);
  border-left-color: rgb(243, 244, 254);
  border-width: 10px;
  margin-top: -10px;
  margin-left: -3px;
}
.message .card.float-right:before {
  left: 100%;
  top: 10px;
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #e6e6e6;
  border-left-color: #e6e6e6;
  border-width: 10px;
  margin-top: -11px;
}

/* BUILDER */
.offer-box .btn-secondary {
  background: #ddd;
  border: 1px solid #ddd;
  color: #222;
  opacity: 0.7;
}
.offer-box .btn-secondary:hover {
  background: #d6d6d6;
  border: 1px solid #d6d6d6;
  color: #222;
  opacity: 1;
}

/* Theming */
.text-kellogg {
  color: #4f2582;
}
.bg-kellogg,
.badge-kellogg {
  background: #4f2582;
}

.btn-kellogg {
  color: #fff;
  background-color: #612ea0;
  border-color: #612ea0;
}

.btn-kellogg:hover {
  color: #fff;
  background-color: #4f2582;
  border-color: #4f2582;
}

.btn-kellogg:focus,
.btn-kellogg.focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 42, 132, 0.5);
}

.btn-kellogg.disabled,
.btn-kellogg:disabled {
  color: #fff;
  background-color: #4f2582;
  border-color: #4f2582;
}

.btn-kellogg:not(:disabled):not(.disabled):active,
.btn-kellogg:not(:disabled):not(.disabled).active,
.show > .btn-kellogg.dropdown-toggle {
  color: #fff;
  background-color: #4f2582;
  border-color: #4f2582;
}

.btn-kellogg:not(:disabled):not(.disabled):active:focus,
.btn-kellogg:not(:disabled):not(.disabled).active:focus,
.show > .btn-kellogg.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 42, 132, 0.5);
}

.btn-outline-kellogg {
  color: #612ea0;
  border-color: #612ea0;
}

.btn-outline-kellogg:hover {
  color: #fff;
  background-color: #4f2582;
  border-color: #4f2582;
}

.btn-outline-kellogg:focus,
.btn-outline-kellogg.focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 42, 132, 0.5);
}

.btn-outline-kellogg.disabled,
.btn-outline-kellogg:disabled {
  color: #4f2582;
  background-color: transparent;
}

.btn-outline-kellogg:not(:disabled):not(.disabled):active,
.btn-outline-kellogg:not(:disabled):not(.disabled).active,
.show > .btn-outline-kellogg.dropdown-toggle {
  color: #fff;
  background-color: #4f2582;
  border-color: #4f2582;
}

.btn-outline-kellogg:not(:disabled):not(.disabled):active:focus,
.btn-outline-kellogg:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-kellogg.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 42, 132, 0.5);
}

/* FORM CREATE SURVEY */
.form-create-survey .form-control {
  /* border: none;
  background-color: #f6f6f6; */
}

/* STEPS */
.step-pill.active .badge {
  box-shadow: 0px 0px 0px 5px rgba(0, 123, 255, 0.3);
}
.step-pill:not(:first-child) .badge::after {
  content: '';
  position: absolute;
  top: calc(50% - 3px);
  right: 50%;
  width: 100%;
  height: 7px;
  background-color: inherit;
  z-index: -1;
}

.sticky-menu.sticky-top {
  position: fixed;
  width: 100%;
  left: 0;
  padding: 15px;
  background: #eee;
  transition: padding, background 0.5s;
}
